import React from 'react'
import {
  LinearProgress,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  const borderRadius = '6.25rem'
  return createStyles({
    root: {
      backgroundColor: 'rgba(249, 148, 76, 0.3)',
      borderRadius,
      height: '0.25rem',
      width: '2rem',
      marginRight: '0.625rem',
      flexShrink: 0,
      [up('md')]: {
        width: '3.6875rem',
        marginRight: '1.25rem',
      },
    },
    bar: {
      backgroundColor: '#F9944C',
      borderRadius,
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  isSelected: boolean
}

const ProgressBar = ({ classes, isSelected }: Props) => {
  const progressValue: number = isSelected ? 50 : 0
  return (
    <LinearProgress
      classes={classes}
      value={progressValue}
      variant="determinate"
      aria-label="Is Selected"
    />
  )
}

export default withStyles(styles)(ProgressBar)
