import React from 'react'
import BaseTitle from '../../../components/SectionToolbar/Title'

const Title = () => (
  <BaseTitle>
    Trusted by Clients
    <br />
    over the world
  </BaseTitle>
)

export default Title
