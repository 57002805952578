import { withStyles, createStyles, Theme } from '@material-ui/core'

import Text from './Text'

const styles = ({ breakpoints: { down } }: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      [down('md')]: {
        fontSize: '0.8125rem',
        lineHeight: '1.0625rem',
      },
    },
  })
}

export default withStyles(styles)(Text)
