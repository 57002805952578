import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Button,
  Theme,
} from '@material-ui/core'

import ProgressBar from './ProgressBar'
import {
  Testimonial,
  SelectedTestimonial,
  SetSelectedTestimonial,
} from '../../../types'
import AuthorInfo from './AuthorInfo'
import Text from './Text'

interface BaseProps extends Testimonial {
  selectedTestimonial: SelectedTestimonial
  setSelectedTestimonial: SetSelectedTestimonial
}

const getColor = (baseProps: BaseProps) => {
  const isSelected: boolean = baseProps.selectedTestimonial === baseProps.author
  return isSelected ? 'white' : '#95a6d0'
}

const getAuthorInfoSpacing = (spacing: string) => ({
  paddingRight: spacing,
  marginRight: spacing,
})

const styles = ({ breakpoints: { up, down } }: Theme) => {
  const sm = up('sm')
  const md = up('md')
  const lg = up('lg')

  const contentFlexBreakpoint = sm

  const companyMinWidth = '8.875rem'

  return createStyles({
    root: {
      listStyle: 'none',
      '& + &': {
        marginTop: '0.4375rem',
        [md]: {
          marginTop: '0.9375rem',
        },
        [lg]: {
          marginTop: '0.4375rem',
        },
      },
    },
    button: {
      color: getColor,
      textAlign: 'left',
      fontWeight: 'bold',
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      justifyContent: 'flex-start',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      [contentFlexBreakpoint]: {
        justifyContent: 'flex-start',
      },
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '.25rem',
      [sm]: {
        marginBottom: 0,
      },
      [lg]: {
        marginRight: '2.5rem',
      },
    },
    company: {
      display: 'block',
      flexShrink: 0,
      flexGrow: 1,
      [down('md')]: {
        fontSize: '1rem',
        lineHeight: '1.3125rem',
      },
      [sm]: {
        minWidth: companyMinWidth,
        flexBasis: companyMinWidth,
      },
      [md]: {
        minWidth: '13.8125rem',
      },
      [lg]: {
        minWidth: 0,
      },
    },
    authorInfoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    author: {
      [contentFlexBreakpoint]: {
        ...getAuthorInfoSpacing('1rem'),
        borderRight: '2px solid',
      },
      [md]: {
        ...getAuthorInfoSpacing('1.5rem'),
      },
      [lg]: {
        borderWidth: '3px',
      },
    },
    role: {
      display: 'none',
      [contentFlexBreakpoint]: {
        display: 'block',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const Company = ({
  classes,
  company,
  selectedTestimonial,
  setSelectedTestimonial,
  author,
  role,
}: Props) => {
  const handleClick = () => {
    setSelectedTestimonial(author)
  }

  const isSelected: boolean = selectedTestimonial === author

  return (
    <li className={classes.root}>
      <Button className={classes.button} onClick={handleClick}>
        <div className={classes.content}>
          <div className={classes.body}>
            <ProgressBar isSelected={isSelected} />
            <Text classes={{ root: classes.company }}>{company || author}</Text>
          </div>
          <div className={classes.authorInfoContainer}>
            {company && (
              <AuthorInfo className={classes.author}>{author}</AuthorInfo>
            )}
            <AuthorInfo className={classes.role}>{role}</AuthorInfo>
          </div>
        </div>
      </Button>
    </li>
  )
}

export default withStyles(styles)(Company)
