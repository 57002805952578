import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'

import { getFlexBreakpoint } from './styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const width: string = '33.85%'
  return createStyles({
    root: {
      width: '100%',
      [flexBreakpoint]: {
        flexBasis: width,
        minWidth: width,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Image = ({ classes }: Props) => {
  return (
    <StaticImage
      className={classes.root}
      src="./section-image.jpg"
      alt="A person with glasses smiling and pointing to their left"
      placeholder="dominantColor"
    />
  )
}

export default withStyles(styles)(Image)
