import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core'

import { Testimonial } from '../types'
import { getFlexBreakpoint } from '../styles'

const styles = (theme: Theme) => {
  const {
    palette: {
      primary: { contrastText },
    },
    breakpoints: { up },
  } = theme

  const md = up('md')
  const lg = up('lg')
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const xl = up('xl')

  return createStyles({
    root: {
      [md]: {
        marginBottom: '3.75rem',
      },
      [flexBreakpoint]: {
        height: '12.5rem',
        overflowY: 'hidden',
      },
      [xl]: {
        height: '12rem',
        marginBottom: '5.3125rem',
      },
    },
    text: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.3125rem',
      color: contrastText,
      [md]: {
        fontSize: '1.5625rem',
        lineHeight: '2.125rem',
      },
      [lg]: {
        fontSize: '1.8rem',
        lineHeight: '2.5rem',
      },
      [xl]: {
        fontSize: '2.1875rem',
        lineHeight: '3rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  content: Testimonial['content']
}

const Quote = ({ classes, content }: Props) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.text}>{content}</Typography>
    </div>
  )
}

export default withStyles(styles)(Quote)
