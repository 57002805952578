import { Typography, Theme, withStyles, createStyles } from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      [up('md')]: {
        fontSize: '1.25rem',
        lineHeight: '1.6875rem',
      },
      [up('lg')]: {
        fontSize: '1.3rem',
        lineHeight: '2.rem',
      },
      [up('xl')]: {
        fontSize: '1.5625rem',
        lineHeight: '2.125rem',
      },
    },
  })
}

export default withStyles(styles)(Typography)
