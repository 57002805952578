import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from '../../../components/PageContainer'
import SectionToolbar from '../../../components/SectionToolbar'
import Title from './Title'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '2.375rem',
      [up('md')]: {
        marginBottom: '3.3125rem',
      },
      [up('xl')]: {
        marginBottom: '6.875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Toolbar = ({ classes }: Props) => {
  return (
    <PageContainer>
      <SectionToolbar
        subtitle="What clients say"
        title={<Title />}
        classes={classes}
      />
    </PageContainer>
  )
}

export default withStyles(styles)(Toolbar)
