import React, { useState } from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import { SelectedTestimonial, Testimonial } from './types'
import Image from './Image'
import Content from './Content'
import Toolbar from './Toolbar'
import { getFlexBreakpoint } from './styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const {
    breakpoints: { up },
  } = theme
  return createStyles({
    root: {
      marginBottom: '2.375rem',
      [up('md')]: {
        marginBottom: '4.3125rem',
      },
      [up('lg')]: {
        marginBottom: '6rem',
      },
      [up('xl')]: {
        marginBottom: '6.875rem',
      },
    },
    container: {
      [flexBreakpoint]: {
        display: 'flex',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Testimonials = ({ classes }: Props) => {
  const testimonials: Testimonial[] = [
    {
      content:
        "Eduardo is a most excellent developer with great work ethics and a very professional development process. He's highly motivated and absolutely knows what he's talking about. He implemented our design spot on!",
      company: 'Hanso Group',
      author: 'Julian Lindner',
      role: 'Founder',
    },
    {
      content:
        'We wholeheartedly recommend that you hire Eduardo’s services for all your web development needs — you will be amazed at his work ethic.',
      company: 'FishCat',
      author: 'Ashwin Naidu',
      role: 'CEO',
    },
    {
      content:
        'I engaged this seller on a custom Gatsby & SCSS project (it was to migrate to Bulma) and he delivered! The output from the gig was exactly what I had described, and he even made suggestions along the way...',
      company: 'Secure Ideas',
      author: 'Jason Gilliam',
      role: 'CIO',
    },
  ]

  const [{ author: firstAuthor }] = testimonials

  const [selectedTestimonial, setSelectedTestimonial] =
    useState<SelectedTestimonial>(firstAuthor)

  return (
    <section className={classes.root}>
      <Toolbar />
      <div className={classes.container}>
        <Image />
        <Content
          testimonials={testimonials}
          selectedTestimonial={selectedTestimonial}
          setSelectedTestimonial={setSelectedTestimonial}
        />
      </div>
    </section>
  )
}

export default withStyles(styles)(Testimonials)
