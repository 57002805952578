import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from '../../../components/PageContainer'
import {
  SelectedTestimonial,
  SetSelectedTestimonial,
  Testimonial,
} from '../types'
import Quote from './Quote'
import Companies from './Companies'
import { getFlexBreakpoint } from '../styles'

const styles = (theme: Theme) => {
  const {
    palette: {
      primary: { main: primaryColor },
    },
    breakpoints: { up },
  } = theme

  const containerPaddingXl: string = '8.9375rem'

  const flexBreakpoint: string = getFlexBreakpoint(theme)
  const xl = up('xl')

  return createStyles({
    root: {
      paddingTop: '2.8125rem',
      paddingBottom: '3.1875rem',
      backgroundColor: primaryColor,
      flexGrow: 1,
      [up('md')]: {
        paddingTop: '3.75rem',
        paddingBottom: '5.6875rem',
      },
      [xl]: {
        paddingTop: '8.1875rem',
        paddingBottom: '5.125rem',
        overflow: 'hidden',
      },
    },
    container: {
      [flexBreakpoint]: {
        maxWidth: '100%',
      },
      [xl]: {
        paddingLeft: containerPaddingXl,
        paddingRight: containerPaddingXl,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  testimonials: Testimonial[]
  setSelectedTestimonial: SetSelectedTestimonial
  selectedTestimonial: SelectedTestimonial
}

const Content = ({
  classes,
  testimonials,
  setSelectedTestimonial,
  selectedTestimonial,
}: Props) => {
  const getIfTestimonialHasAuthor =
    (author: Testimonial['author']) =>
    (testimonial: Testimonial): boolean =>
      testimonial.author === author

  type PossibleTestimonial = Testimonial | undefined

  const findSelectedTestimonial = (): PossibleTestimonial => {
    return testimonials.find(getIfTestimonialHasAuthor(selectedTestimonial))
  }

  const getSelectedTestimonial = (): Testimonial => {
    const testimonial: PossibleTestimonial = findSelectedTestimonial()
    if (testimonial) return testimonial
    throw new Error('Selected testimonial not found.')
  }

  const getTestimonialContent = (): JSX.Element | null => {
    const fullSelectedTestimonial: Testimonial = getSelectedTestimonial()
    return <Quote content={fullSelectedTestimonial.content} />
  }

  const content: JSX.Element | null = getTestimonialContent()

  return (
    <div className={classes.root}>
      <PageContainer classes={{ root: classes.container }}>
        {content}
        <Companies
          testimonials={testimonials}
          setSelectedTestimonial={setSelectedTestimonial}
          selectedTestimonial={selectedTestimonial}
        />
      </PageContainer>
    </div>
  )
}

export default withStyles(styles)(Content)
