import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import Company from './Company'
import {
  SelectedTestimonial,
  SetSelectedTestimonial,
  Testimonial,
} from '../../types'

const styles = () => {
  return createStyles({
    root: {
      padding: 0,
      marginTop: '2.3125rem',
      marginBottom: 0,
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  setSelectedTestimonial: SetSelectedTestimonial
  selectedTestimonial: SelectedTestimonial
  testimonials: Testimonial[]
}

const Companies = ({
  classes,
  testimonials,
  setSelectedTestimonial,
  selectedTestimonial,
}: Props) => {
  const renderCompany = (testimonial: Testimonial): JSX.Element => (
    <Company
      {...testimonial}
      setSelectedTestimonial={setSelectedTestimonial}
      selectedTestimonial={selectedTestimonial}
      key={testimonial.author}
    />
  )

  const renderedCompanies: JSX.Element[] = testimonials.map(renderCompany)

  return <ul className={classes.root}>{renderedCompanies}</ul>
}

export default withStyles(styles)(Companies)
